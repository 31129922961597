import React, {useState} from 'react';
import './App.css';
import pixel4 from "./assets/pixel_4.svg";
import gear from "./assets/gear.svg";
import screen from "./assets/screen.svg";
import work from "./assets/work.svg";
import downloads from "./assets/download.svg";
import star from "./assets/star.svg";
import user from "./assets/user.svg";

const App: React.FC = () => {
    return (
        <div>
            <NavBar/>
            <Header/>
            <main>
                <WhatWeAreDoing/>
                <Portfolio/>
                <QuotaForm/>
                <ContactUsForm/>
            </main>
            <Footer/>
        </div>
    );
};

const NavBar: React.FunctionComponent<{}> = props => {
    return <nav>
        <div style={{
            display: "flex",
            position: "sticky",
            backgroundColor: "#007bff",
            flexDirection: "row",
            color: "white",
            padding: "16px",
            maxHeight: "1.5cm",
            lineHeight: "0"
        }}>
            <h1>NilPointer Software</h1>
        </div>
    </nav>
};

interface Link {
    name: string,
    link: string,
}

interface Stat {
    iconUrl: string,
    alt: string,
    content: string,
}

const PortfolioItem: React.FunctionComponent<{ title: string, company: string, description: string, stats: Stat[], links: Link[] }> = props => {
    const renderedStats = props.stats.map((s) => <PortfolioStat key={s.content} content={s.content} imageUrl={s.iconUrl}
                                                                alt={s.alt}/>);
    const renderedLinks = props.links.map((l) => <a style={{marginRight: "8px"}} key={l.link}
                                                    href={l.link}>{l.name}</a>);

    return <div style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "10px",
        maxWidth: "15cm",
        maxHeight: "7.5cm",
        flexGrow: 1,
        padding: "12px 25px",
        margin: "8px",
    }}>
        <h3 style={{marginBlockStart: 0, marginBlockEnd: "8px"}}>{props.title}</h3>
        <div style={{display: "flex", justifyContent: "start", alignContent: "center"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                <img src={work} alt={"Suitcase (work) icon"} height={12}/>
            </div>
            <p style={{
                fontSize: "15px",
                textAlign: "left",
                marginBlockStart: 0,
                marginBlockEnd: 0,
                marginLeft: "8px"
            }}>{props.company}</p>
        </div>
        <div style={{marginTop: "8px"}}>
            <Content>
                <p style={{marginBlockStart: 0, marginBlockEnd: 0, fontSize: "14px"}}>
                    {props.description}
                </p>
            </Content>
        </div>
        <div style={{display: "flex", justifyContent: "space-between", alignContent: "center", marginTop: "8px"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
                {renderedLinks}
            </div>
            <div style={{display: "flex", flexDirection: "row"}}>
                {renderedStats}
            </div>
        </div>
    </div>
};

const PortfolioStat: React.FunctionComponent<{ content: string, imageUrl: string, alt: string }> = props => {
    return <div style={{display: "flex", flexDirection: "row", marginLeft: "4px", marginRight: "4px"}}>
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <img src={props.imageUrl} height={12} alt={props.alt}/>
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <span style={{fontSize: "15px"}}>{props.content}</span>
        </div>
    </div>
};

const Header: React.FunctionComponent<{}> = props => {
    return <header>
        <div style={{
            color: "white",
            marginTop: "-22px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "4cm",
            paddingBottom: "4cm",
            backgroundColor: "#007bff",
            clipPath: "polygon(0 0, 100% 0, 100% 70%, 0 100%)"
        }}>
            <Content>
                <h1>Try our services</h1>
                <p>We are new start-up focused on creating useful, fast and beautiful software that is providing awesome
                    user experience</p>
            </Content>
        </div>
    </header>
};

const WhatWeAreDoing: React.FunctionComponent<{}> = props => {
    return <section>
        <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
            <WhiteTile
                title={"Mobile applications"}
                imgSrc={pixel4}
                imgAlt={"Smartphone"}
                description={"We create beautiful and fast mobile apps, that targets both Android & iOS from single code base"}
            />
            <WhiteTile
                title={"Serverless backends"}
                imgSrc={gear}
                imgAlt={"Gear"}
                description={"We create infinitely scalable backends, that can scale from 0 to infinity in seconds"}
            />
            <WhiteTile
                title={"Websites"}
                imgSrc={screen}
                imgAlt={"Gear"}
                description={"We create responsive websites and PWAs that are fast, modern and beautiful (like this website)"}
            />

        </div>
    </section>
};

const Portfolio: React.FunctionComponent<{}> = props => {
    return <section>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            flexWrap: "wrap",
            textAlign: "center",
            marginTop: "8px",
            marginBottom: "12px"
        }}>
            <h2>Our projects</h2>
            <PortfolioItem
            title={"Bot Designer For Discord"}
            company={"NilPointer Software"}
            description={"Ever dreamed about crating own Discord bot, but you don't know programming? Nothing lost! Here is our app that will let you do that with few simple clicks!"}
            links={[
                {
                    name: "Website",
                    link: "https://botdesignerdiscord.com"
                },
                {
                    name: "Google Play",
                    link: "https://play.google.com/store/apps/details?id=com.jakubtomana.discordbotdesinger"
                },
                {
                    name: "Appstore",
                    link: "https://apps.apple.com/us/app/bot-designer-for-discord/id1495536477"
                },
            ]}
            stats={[
                {
                    iconUrl: downloads,
                    alt: "Download icon",
                    content: "~1,000,000 downloads"
                },
                {
                    iconUrl: star,
                    alt: "Star (rating) icon",
                    content: "~4.0"
                },
            ]}
        />
            <PortfolioItem
                title={"Bot Designer List"}
                company={"NilPointer Software"}
                description={"Awesome Discord bot and server list, that is deeply integrated with Bot Designer For Discord app."}
                links={[
                    {
                        name: "Website",
                        link: "https://botdesignerlist.com"
                    },
                ]}
                stats={[
                    {
                        iconUrl: user,
                        alt: "User icon",
                        content: "~500 daily active users"
                    },
                ]}
            />
            <PortfolioItem
                title={"HypeRCON Minecraft"}
                company={"NilPointer Software"}
                description={"Ever wanted to control your Minecraft server when you were away from home? " +
                "Now we have got a solution for you! HypeRCON connects to your server via RCON protocol and allows remote chat and commands access."}
                links={[
                    {
                        name: "Google Play",
                        link: "https://play.google.com/store/apps/details?id=com.jakubtomana.hypercon.minecraft"
                    },
                    {
                        name: "Appstore",
                        link: "https://apps.apple.com/mt/app/hypercon-rcon-client/id1504105021"
                    },
                ]}
                stats={[
                    {
                        iconUrl: downloads,
                        alt: "Download icon",
                        content: ">15,000 downloads"
                    },
                    {
                        iconUrl: star,
                        alt: "Star (rating) icon",
                        content: "~4.0"
                    },
                ]}
            />
        </div>
    </section>
};

type ProjectType = "app" | "website" | "pwa" | "backend" | "rust" | "other";

const QuotaForm: React.FunctionComponent<{}> = props => {
    const [userName, setUserName] = useState("");
    const [userMail, setUserMail] = useState("");
    const [projectType, setProjectType] = useState<ProjectType>("app");
    const [projectDetails, setProjectDetails] = useState("");
    const [processingRequest, setProcessingRequest] = useState(false);
    const [requestSend, setRequestSend] = useState(false);

    const processRequest = async () => {
        // Send it to firebase or idk where
        setProcessingRequest(true);
        let formJson: string = JSON.stringify({
            userName,
            userMail,
            projectType,
            projectDetails,
        });
        await fetch(new Request("api/submit_process_request", {method: "POST", body: formJson}));
        clearForm();
    };

    const clearForm = () => {
        // Send to to some api, maybe firebase idk
        setUserName("");
        setUserMail("");
        setProjectType("other");
        setProjectDetails("");
        setProjectDetails("");
        setProcessingRequest(false);
        setRequestSend(false);
    };


    return <section>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignContent: "center"
        }}>
            <h2>Request quota on your project</h2>
            <Content>
                {/*Add form - dropdown on project type (mobile app, website, other), project details complexity etc.*/}
                <div style={{display: "flex", flexDirection: "column", padding: "8px"}}>
                    <p>Your name</p>
                    <input type={"text"} placeholder={"John Smith"} onChange={(v) => setUserName(v.target.value)}
                           value={userName}/>
                    <p>Your e-mail</p>
                    <input type={"email"} placeholder={"john.smith@gmail.com"}
                           onChange={(v) => setUserMail(v.target.value)} value={userMail}/>
                    <p>Project type</p>
                    <select onChange={(v) => setProjectType(v.target.value as ProjectType)} value={projectType}>
                        <option value="app">Mobile application</option>
                        <option value="website">Website</option>
                        <option value="pwa">Progressive Web App</option>
                        <option value="backend">Scalable Serverless Backend</option>
                        <option value="rust">Native data processing software</option>
                        <option value="other">Other</option>
                    </select>
                    <p>Project details</p>
                    <textarea rows={15} placeholder={"I need mobile app that will have 3 screens...."}
                              onChange={(v) => setProjectDetails(v.target.value)} value={projectDetails}/>
                    <button style={{marginTop: "16px"}} onClick={(_) => processRequest()}>
                        {requestSend ? "Request send!" : processingRequest ? "Processing request..." : "Request quota"}
                    </button>
                </div>
            </Content>
        </div>
    </section>;
};

const ContactUsForm: React.FunctionComponent<{}> = props => {
    return <section>
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignContent: "center",
            marginBottom: "16px"
        }}>
            <h2 style={{marginBlockEnd: 0}}>Contact us</h2>
            {/* Mail contact form, maybe link to our facebook page */}
            <p>By email: contact<span>@</span>nilpointer.software</p>
            <a style={{fontSize: "inherit"}} href={"https://m.me/nilpointersoftware"}>Facebook messenger</a>
        </div>
    </section>;
};

const WhiteTile: React.FunctionComponent<{ title: string, imgSrc: string, imgAlt: string, description: string }> = props => {
    return <div
        style={{
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            padding: "40px 20px",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            margin: "8px",
            maxWidth: "8cm",
            justifyContent: "space-between",
        }}>
        <h3 style={{marginBlockStart: "0", marginBlockEnd: "0", textAlign: "center"}}>{props.title}</h3><br/>
        <img src={props.imgSrc} alt={props.imgAlt} height={60}/>
        <p style={{fontSize: "16px"}}>{props.description}</p>
    </div>
};

const Footer: React.FunctionComponent = props => {
    return <footer>
        <div style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#eeeeee",
            fontSize: 12,
            textAlign: "center",
            paddingBottom: "8px"
        }}>
            Company Details:<br/>
            NilPointer Software Jakub Tomana<br/>
            NIP: 6793194260<br/>
            REGON: 385199820<br/>
            All rights reserved.<br/>
            Copyright © {new Date().getFullYear()} NilPointer Software Jakub Tomana
        </div>
    </footer>;
};

const Content: React.FunctionComponent<{}> = props => {
    return <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
        <div style={{maxWidth: "15cm", textAlign: "left", flexGrow: 1}}>
            {props.children}
        </div>
    </div>
};
export default App;
